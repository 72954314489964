const images = {
    anniversary1: require("./assets/images/anniversary1.webp"),
    anniversary1fb: require("./assets/images/anniversary1.jpg"),
    anniversary2: require("./assets/images/anniversary2.webp"),
    anniversary2fb: require("./assets/images/anniversary2.jpg"),
    anniversary3: require("./assets/images/anniversary3.webp"),
    anniversary3fb: require("./assets/images/anniversary3.jpg"),
    pennantL: require("./assets/images/pennantL.webp"),
    pennantR: require("./assets/images/pennantR.webp"),
    pennantLD: require("./assets/images/pennantLD.webp"),
    pennantRD: require("./assets/images/pennantRD.webp"),
    pennantLfb: require("./assets/images/pennantL.png"),
    pennantRfb: require("./assets/images/pennantR.png"),
    pennantLDfb: require("./assets/images/pennantLD.png"),
    pennantRDfb: require("./assets/images/pennantRD.png"),
    flyer1: require("./assets/images/flyer1.webp"),
    flyer1fb: require("./assets/images/flyer1.jpg"),
    social1: require("./assets/images/social5.webp"),
    social1fb: require("./assets/images/social5.jpg"),
    social2: require("./assets/images/ws10.webp"),
    social2fb: require("./assets/images/ws10.jpg"),
    social3: require("./assets/images/social3.webp"),
    social3fb: require("./assets/images/social3.jpg"),
    social4: require("./assets/images/ws10.webp"),
    social4fb: require("./assets/images/ws10.jpg"),
    studio1: require("./assets/images/studio6.jpg"),
    studio1fb: require("./assets/images/studio6.jpg"),
    studio2: require("./assets/images/studio7.webp"),
    studio2fb: require("./assets/images/studio7.jpg"),
    studio3: require("./assets/images/studio8.webp"),
    studio3fb: require("./assets/images/studio8.jpg"),
};

export default images;